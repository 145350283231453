<template>
  <div class="addImplement">
    <div class="addImplement-wrap">
      <div class="addImplement-top">
        <div>{{ addtitle }}</div>
        <img @click="closefn"
             src="@/assets/images/pop01.png"
             alt="" />
      </div>
      <div class="addImplement-content">
        <div class="addImplement-input">
          <span>选择权限</span>
          <div>
            <el-select v-model="jurisdictionid"
                       class="m-2"
                       placeholder="请选择"
                       size="large">
              <el-option v-for="item in listdata"
                         :key="item.ID"
                         :label="item.Name"
                         :value="item.ID">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="addImplement-content">
        <div class="addImplement-input">
          <span>登录账号</span>
          <div>
            <!-- <el-form-item :error='errorMsg1'> -->
            <el-input v-model.number="inputs1"
                      placeholder="请输入"
                      @blur="selefn"></el-input>
            <span class="pwdcheck"
                  v-if="errorMsg1">({{errorMsg1}})</span>
            <!-- </el-form-item> -->

          </div>
        </div>
      </div>
      <div class="addImplement-content pwd">
        <div class="addImplement-input">
          <span>登录密码</span>
          <div class="pwdinput-c">
            <el-input v-model="inputs2"
                      placeholder="请输入"
                      show-password="true"
                      @change="pwdinput"></el-input>
            <div class="tips"
                 v-if="!pwdcheck && inputs2">密码格式错误</div>
          </div>
          <div class="pwdcheck">（数字或字母组合、不超过8位）</div>
        </div>
      </div>
      <div class="addImplement-content pwd"
           v-if="!editid">
        <!-- <div class="addImplement-input">
          <span>是否微信用户</span>
          <div class = "pwdinput-c">
            <el-radio v-model="radio1" label="1" size="large">是</el-radio>
            <el-radio v-model="radio1" label="2" size="large">否</el-radio>
          </div>
        </div> -->
      </div>
      <div class="addImplement-bottom">
        <button @click="closefn"
                class="def">取消</button>
        <button @click="AddExtractApplyfn"
                :class="{ disable: !inputs1 || !inputs2 || !jurisdictionid || !pwdcheck ||errorMsg1}">
          确定
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
import { ElMessage } from "element-plus";
import { getStorage } from "@/js/common";
import { AddUser, RoleList, GetJudgeUserIsExist } from "@/js/systemManagementApi";
export default {
  props: {
    addtitle: String,
    editdata: Object,
  },
  setup (props, { emit }) {
    const state = reactive({
      jurisdictionid: '',
      listdata: [],
      inputs1: "", //
      inputs2: "", //
      pwdcheck: true,
      editid: '',
      radio1: '1',
      errorMsg1: '',
    });
    const checkpwd = (str) => {
      const preg = /^[a-zA-Z0-9]{0,8}$/;
      return preg.test(str);
    }
    const closefn = () => {
      emit("addfalsefn");
    };
    const cdata = getStorage("bscdata");


    // 获取权限列表
    const getRoleList = () => {
      let datas = {
        ProjectID: cdata.pid,
        pageIndex: 1,
        pageSize: 9999,
      }
      RoleList(datas).then((res) => {
        console.log(res, 'list');
        if (res.data.Code == 1) {
          state.listdata = res.data.Data.data;
        } else {
          ElMessage({
            showClose: true,
            message: res.data.message,
            type: "error",
          });
        }
      })
    }
    const methods = {
      selefn: () => {
        state.errorMsg1 = ''
        if (!state.inputs1) {
          return
        }
        let datas = {
          UserName: state.inputs1,
          id: state.addtitle == "编辑用户" ? props.editdata.ID : null
        }
        GetJudgeUserIsExist(datas).then((res) => {
          if (res.data.Code != 1) {
            state.errorMsg1 = res.data.Message
          }
          console.log(res)
        })
      },
      pwdinput: () => {
        state.pwdcheck = checkpwd(state.inputs2);
        console.log(state.pwdcheck)
      },
      AddExtractApplyfn: () => {
        methods.selefn()
        if (!state.inputs1 || !state.inputs2 || !state.jurisdictionid || !state.pwdcheck || state.errorMsg1) {
          return;
        }
        let datas = {
          "userName": state.inputs1,
          "userPassword": state.inputs2,
          "isFrozen": true,
          "projectId": cdata.pid,
          "roleId": state.jurisdictionid,
          "IsWeChat": state.radio1 == 1 ? true : false,
          "IsAudit": true
        }
        if (state.editid) {
          datas.id = state.editid;
        }
        AddUser(datas).then((res) => {
          let { Code, Message } = res.data;
          if (Code == 1) {
            emit("GetPageList");
            emit("addfalsefn");
            ElMessage({
              showClose: true,
              message: Message,
              type: "success",
            });
          } else {
            ElMessage({
              showClose: true,
              message: Message,
              type: "error",
            });
          }
        })
      },
    };
    onMounted(() => {
      state.addtitle = props.addtitle;
      getRoleList();
      if (state.addtitle == "编辑用户") {
        console.log(props.editdata, 189)
        state.inputs1 = props.editdata.UserName;
        state.inputs2 = props.editdata.UserPassword;
        state.jurisdictionid = props.editdata.RoleId;

        state.editid = props.editdata.ID;
        // gr();
      }
    });
    return {
      ...methods,
      closefn,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.addImplement {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  .pwdcheck {
    padding: 0 0 0 20px;
    font-size: 14px;
    color: #666;
  }
  .tips {
    font-size: 14px;
    color: rgb(219, 114, 114);
    position: absolute;
    bottom: -20px;
    left: 10px;
  }
  .pwdinput-c {
    position: relative;
  }
  .addImplement-wrap {
    width: 576px;
    min-height: 235px;
    background: #ffffff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .addImplement-top {
      padding: 0 20px;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #e3edf8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #666666;
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }
  .add-wrap {
    max-height: 500px;
    overflow-y: auto;
  }
  .addImplement-content {
    width: 100%;
    padding: 0 20px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    align-items: center;
    &.pwd {
      padding: 0 20px 40px;
    }
    .annotation {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #00a5ff;
      text-align: center;
      margin-top: 5px;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .addImplement-input {
      display: flex;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #555555;
      margin-top: 20px;
      box-sizing: border-box;
      align-items: center;
      .el-input,
      .el-select {
        width: 200px;
      }

      &.flex2 {
        align-items: flex-start;
        .el-checkbox-group {
          .el-checkbox {
            margin-bottom: 10px;
          }
        }
      }
      span {
        display: inline-block;
        padding-right: 11px;
        box-sizing: border-box;
        word-break: keep-all;
      }
    }

    .dels {
      width: 30px;
      height: 30px;
      display: flex;
      margin-top: auto;
    }

    .addImplement-input1 {
      margin: 20px 20px 0;
      box-sizing: border-box;
      div {
        width: 100px;
      }
    }
  }

  .addImplement-add {
    margin-left: 109px;
    margin-bottom: 20px;
    .addImplement-btn {
      width: 128px;
      height: 32px;
      background: rgba(0, 180, 255, 0.1);
      border-radius: 4px;
      border: 1px solid #0083ff;
      font-size: 12px;
      color: #0083ff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 12px;
        height: 12px;
        margin-right: 3px;
        box-sizing: border-box;
      }
    }
  }

  .addImplement-bottom {
    text-align: right;
    padding: 10px 20px;
    box-sizing: border-box;
    border-top: 1px solid #c6c6c6;
    button {
      display: inline-block;
      width: 68px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #c6c6c6;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #adbaae;
      margin-left: 10px;
      box-sizing: border-box;
      cursor: pointer;

      // &:nth-child(2) {
      background: #027aff;
      color: #fff;
      // }
      &.def {
        background: #fff;
        color: #adbaae;
      }
      &.disable {
        background: #fff;
        color: #adbaae;
        cursor: no-drop;
        opacity: 0.5;
      }
    }
  }
}
</style>
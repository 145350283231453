<template>
  <div id="user">
    <div class="Implement-add">
      <div class="adds" @click="addfn(1)">添加用户</div>
    </div>

    <div class="Implement-wrap">
      <div class="Implement-title">
        <span class="title-01">序号</span>
        <span class="title-02">权限名称</span>
        <span class="title-03">账号</span>
        <span class="title-04">操作</span>
      </div>

      <div
        class="Implement-list"
        v-for="(item, index) in listdata"
        :key="index"
      >
        <span class="title-01 textOverflowHide">{{ index + 1 }}</span>
        <span class="title-02 textOverflowHide">{{ item.RoleName }}</span>
        <span class="title-03 textOverflowHide fs">
          <span>账号：{{ item.UserName }}</span>
          <span>密码：{{ item.UserPassword }}</span>
        </span>
        <span class="title-04 textOverflowHide">
            <el-select v-model="item.IsFrozen" class="m-2" placeholder="Select" size="default"  @change="addfn(3, item)">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"

                  >
                  </el-option>
                </el-select>
          <!-- <i @click="addfn(3, item)" :class="{ frozen: item.IsFrozen }">{{
            item.IsFrozen ? "冻结" : "解冻"
          }}</i> -->
          <i @click="addfn(2, item)">编辑</i>
          <i @click="delfn(item)">删除</i>
        </span>
      </div>
    </div>
    <div class="pages" v-if="total && total > pageSizes">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSizes"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <adduser
      v-if="addfalse"
      :addtitle="addtitle"
      :editdata="editdata"
      @addfalsefn="addfalsefn"
      @GetPageList="GetPageList"
    ></adduser>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
import adduser from "@/components/adduser.vue";
import { GetPageList, DeleteUser, IsFrozen } from "@/js/systemManagementApi";
import { getStorage } from "@/js/common";
export default {
  name: "",
  props: {},

  setup(props) {
    const state = reactive({
      total: 0,
      currentPage: 1,
      pageSizes: 10,
      addfalse: false,
      addtitle: "",
      listdata: [],
      editdata: "",
      options:[
        {
          value:true,
          label:'未冻结',
        },
        {
          value:false,
          label:'冻结',
        },
      ],
    });
    const cdata = getStorage("bscdata");
    const methods = {
      // 添加弹框
      addfn: (id, item) => {
   
        if (id == 1) {
          state.addtitle = "添加用户";
          state.addfalse = !state.addfalse;
        } else if (id == 2) {
          state.addtitle = "编辑用户";
          state.editdata = item;
          state.addfalse = !state.addfalse;
        } else if (id == 3) {
          // 冻结或解冻
          IsFrozen({ ID: item.ID }).then((res) => {
            
            if (res.data.Code == 1) {
              ElMessage({
                showClose: true,
                message: res.data.Message,
                type: "success",
              });
              methods.GetPageList();
            } else {
              ElMessage({
                showClose: true,
                message: res.data.Message,
                type: "error",
              });
            }
          });
        }
      },
      //添加弹框 关闭弹框
      addfalsefn() {
        state.addfalse = false;
      },
      // 列表获取分页数据
      handleCurrentChange: (val) => {
        state.currentPage = val;
        methods.GetPageList()
      },

      // 获取权限列表
      GetPageList: () => {
        let datas = {
          ProjectID: cdata.pid,
          pageIndex: state.currentPage,
          pageSize: state.pageSizes,
        };
        GetPageList(datas).then((res) => {
          // 添加工程图片
       
          if (res.data.Code == 1) {
            state.listdata = res.data.Data.data;
            state.total = res.data.Data.total.Value;
          } else {
            ElMessage({
              showClose: true,
              message: res.data.message,
              type: "error",
            });
          }
        });
      },
      // 删除权限
      delfn(item) {
        ElMessageBox.confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let datas = {
              // ProjectID:cdata.pid,
              ID: item.ID,
            };
            DeleteUser(datas).then((res) => {
             
              if (res.data.Code == 1) {
                ElMessage({
                  showClose: true,
                  message: res.data.Message,
                  type: "success",
                });
                methods.GetPageList();
              } else {
                ElMessage({
                  showClose: true,
                  message: res.data.Message,
                  type: "error",
                });
              }
            });
          })
          .catch(() => {
            return false;
          });
      },
    };

    onMounted(() => {
      methods.GetPageList();
    });

    return {
      ...methods,
      ...toRefs(state),
    };
  },

  components: {
    adduser,
  },
};
</script>

<style lang='scss' scoped>
#user {
  margin: 0 30px;
  box-sizing: border-box;
  .Implement-add {
    height: 76px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    box-sizing: border-box;
    .adds {
      width: 107px;
      height: 36px;
      background: #0083ff;
      border-radius: 5px;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
  }

  .Implement-title,
  .Implement-list {
    width: 100%;
    height: 40px;
    background: #e8f3f8;
    border: 1px solid #d1e2e5;
    font-size: 12px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #999999;
    display: inline-flex;
    align-items: center;
  }
  .Implement-list {
    background: transparent;
      &:deep(.el-select){
         margin-top: 5px;
      width: 120px;
      height: 30px;
      line-height: 30px;
      .el-input,.el-input__inner,.el-input__icon{
        height: 30px;
      line-height: 30px;
      }
      
    }
    span {
      i {
        cursor: pointer;
        &:nth-child(1) {
          &.frozen {
            color: #e7111b;
          }
          color: #57bf7c;
        }
        &:nth-child(2) {
          color: #0083ff;
        }

        &:nth-child(3) {
          color: #e7111b;
        }
      }
    }
  
    .title-04 {
      display: flex;
      padding: 0;
      justify-content: space-around;
    }
    .title-03 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      span {
        min-width: 150px;
        &:nth-child(1) {
          padding: 0 50px 0 0;
        }
      }
    }
  }
  .Implement-list {
    border-top: none;
  }
  .Implement-title > span,
  .Implement-list > span {
    display: inline-block;
    line-height: 40px;
    padding-left: 1%;
    border-right: 1px solid #d1e2e5;
    box-sizing: border-box;
    min-height: 40px;
  }
  .title-01 {
    width: 4%;
  }
  .title-02 {
    width: 38%;
  }
  .title-03 {
    width: 38%;
  }
  .title-04 {
    width: 20%;
  }

  .pages {
    margin-top: 20px;
    box-sizing: border-box;
    float: right;
  }
}
</style>